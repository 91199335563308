import React from 'react';
import { ComponentPreview, Paragraph, SectionSubhead } from 'docComponents';
import {
  FormModifier,
  LookupMultiSelect,
  LookupSelect,
  SingleSelect,
} from 'hudl-uniform-ui-components';

const groupOptions = {
  group1: {
    label: 'Offense',
    options: [
      { label: 'Kareem Abdul-Jabbar', value: 'kareem' },
      { label: 'Marv Albert', value: 'marv', disabled: true },
      { label: 'Al Attles', value: 'al' },
      { label: 'Red Auerbach', value: 'red' },
    ],
  },

  group2: {
    label: 'Defense',
    options: [
      { label: 'Elgin Baylor', value: 'elgin' },
      { label: 'Dave Bing', value: 'dave' },
      { label: 'Larry Bird', value: 'larry' },
    ],
  },
};

const OptionGroups = ({ type }) => {
  const placeholderText =
    type === 'lookup' || type === 'multi'
      ? 'Find an athlete'
      : 'Select an athlete';

  return (
    <div>
      <SectionSubhead>Option Groups</SectionSubhead>
      <Paragraph>
        Use option groups to divide larger lists into logical sections, like
        putting the most popular items at the top. You can also add labels for
        increased clarity.
      </Paragraph>
      <ComponentPreview name="SingleSelectGroup" layout="halfWidth">
        <FormModifier size="medium">
          {type === 'multi' && (
            <LookupMultiSelect
              options={groupOptions}
              placeholder={placeholderText}
            />
          )}
          {type === 'lookup' && (
            <LookupSelect
              options={groupOptions}
              placeholder={placeholderText}
            />
          )}
          {type === 'single' && (
            <SingleSelect
              options={groupOptions}
              placeholder={placeholderText}
            />
          )}
        </FormModifier>
      </ComponentPreview>
    </div>
  );
};

export default OptionGroups;
