import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from 'docComponents';
import { FormModifier, LookupMultiSelect } from 'hudl-uniform-ui-components';
import SectionPlatform from '../partials/_SectionPlatform';
import SectionMobile from '../partials/_SectionMobile';
import OptionGroups from '../partials/_OptionGroups';
import singleSelectPreviewData from '../../../../data/previews/lookup-multi-select.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const options = [
  { label: 'Kareem Abdul-Jabbar', value: 'kareem' },
  { label: 'Marv Albert', value: 'marv' },
  { label: 'Al Attles', value: 'al' },
  { label: 'Red Auerbach', value: 'red' },
  { label: 'Elgin Baylor', value: 'elgin' },
  { label: 'Dave Bing', value: 'dave' },
  { label: 'Larry Bird', value: 'larry' },
];

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Lookup Multi-Select"
      designCode
      subnav="components">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Selects"
        tierThree="Lookup Multi-Select"
      />

      <PageNavigation>
        <PageNavigationLink>Size</PageNavigationLink>
        <PageNavigationLink>Dismiss</PageNavigationLink>
        <PageNavigationLink>Mobile</PageNavigationLink>
        <PageNavigationLink>Usage</PageNavigationLink>
        <PageNavigationLink>Microcopy</PageNavigationLink>
        <PageNavigationLink>Platform</PageNavigationLink>
      </PageNavigation>

      <Section title="Size">
        <Paragraph>
          Consider interface density and the select’s position when choosing
          between the three available sizes. (Sizing itself is controlled by the{' '}
          <Link href="/components/forms/form-modifier" isDesignCodeLink>
            form modifier component
          </Link>
          .)
        </Paragraph>
        <ComponentPreview
          name="SingleSelectSize"
          layout="halfWidth"
          previewData={singleSelectPreviewData.sizes}>
          <FormModifier>
            <LookupMultiSelect
              ignoreThisComponent
              placeholder="Find an athlete"
              options={options}
            />
          </FormModifier>
        </ComponentPreview>
      </Section>

      <Section title="Dismiss">
        <Paragraph>
          Selected items can be dismissed in one of three ways.
        </Paragraph>
        <ComponentPreview
          name="SingleSelectSize"
          layout="halfWidth"
          previewData={singleSelectPreviewData.dismisses}>
          <FormModifier>
            <LookupMultiSelect
              ignoreThisComponent
              placeholder="Find an athlete"
              options={options}
              defaultValues={[
                { label: 'Al Attles', value: 'al' },
                { label: 'Red Auerbach', value: 'red' },
                { label: 'Elgin Baylor', value: 'elgin' },
              ]}
            />
          </FormModifier>
        </ComponentPreview>
      </Section>

      <SectionMobile />

      <Section title="Usage">
        <SectionSubhead>List Length</SectionSubhead>
        <Paragraph>
          Like the{' '}
          <Link href="/components/selects/lookup-select" isDesignCodeLink>
            lookup select
          </Link>
          , a lookup multi-select works well when your list is 15+ items long.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="list three items they could easily select with a checkbox."
            img="selects-lookupmultilength-dont"
          />
          <DontDoItem
            type="do"
            text="justify the typeahead with a larger list to filter."
            img="selects-lookupmultilength-do"
          />
        </DontDo>
        <Paragraph>
          For shorter lists, read the{' '}
          <Link href="/components/selects/single-select" isDesignCodeLink>
            checkbox guidelines
          </Link>
          .
        </Paragraph>

        <SectionSubhead>Options</SectionSubhead>
        <Paragraph>
          Option content should be familiar to the user–they should have an idea
          of what’s in the list before opening the select. That familiarity
          helps them decide what to type.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="frame every item differently in an unhelpful order."
            img="selects-lookupmultiorder-dont"
          />
          <DontDoItem
            type="do"
            text="provide a logical list of related items."
            img="selects-lookupmultiorder-do"
          />
        </DontDo>

        <OptionGroups type="multi" />

        <SectionSubhead>List Height</SectionSubhead>
        <Paragraph>
          Allow enough space in your interface or adjust the max-height to
          prevent the option list from expanding beyond the screen.
        </Paragraph>
        <Paragraph>
          By default, 6.5 items will show when the option list is expanded. If
          you use option groups, adjust the max-height to ensure it falls on a ½
          item to signal the user can scroll.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="allow overflow to land between options making it look like all options are visible."
            img="selects-lookupmultioverflow-dont"
          />
          <DontDoItem
            type="do"
            text="size the maximum height to split an option to make scrolling obvious."
            img="selects-lookupmultioverflow-do"
          />
        </DontDo>

        <SectionSubhead>Max-Height</SectionSubhead>
        <Paragraph>
          Should the selected items exceed one line, the default max is set to
          2.5rem. If you need something larger, we suggest the half-line to
          indicate more items are listed and the user can scroll.
        </Paragraph>
      </Section>

      <Section title="Microcopy">
        <SectionSubhead>Placeholder</SectionSubhead>
        <Paragraph>
          The multi-select placeholder should read “Select <em>items</em>
          ,” making it clear that the user can and should choose more than one.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="put a single object in the placeholder."
            img="selects-lookupmultiplaceholder-dont"
          />
          <DontDoItem
            type="do"
            text="provide direction with “select” as the action."
            img="selects-lookupmultiplaceholder-do"
          />
        </DontDo>

        <SectionSubhead>Options</SectionSubhead>
        <Paragraph>
          Treat all options as{' '}
          <Link href="/words/content-elements/labels-non-label-text#Non-Label%20UI%20Text">
            non-label text{' '}
          </Link>
          and only capitalize the first word, both in the list and as pills.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="capitalize every word in an option (unless it’s a proper name)."
            img="selects-lookupmulticapitalize-dont"
          />
          <DontDoItem
            type="do"
            text="keep consistent capitalization from option list to pill."
            img="selects-lookupmulticapitalize-do"
          />
        </DontDo>
      </Section>

      <SectionPlatform />
    </PageWithSubNav>
  );
};

export default IndexPage;
