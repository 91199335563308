import React from 'react';
import {
  Link,
  List,
  PlatformTable,
  PlatformTableRow,
  Section,
} from 'docComponents';

const SectionPlatform = () => {
  return (
    <Section title="Platform">
      <PlatformTable footerMessage="No guidelines exist for Windows (yet).">
        <PlatformTableRow platform="web" />
        <PlatformTableRow platform="apple">
          <List>
            <li>
              Follow{' '}
              <Link href="https://developer.apple.com/ios/human-interface-guidelines/controls/pickers/">
                HIG
              </Link>
              .
            </li>
          </List>
        </PlatformTableRow>
        <PlatformTableRow platform="android">
          <List>
            <li>
              Follow{' '}
              <Link href="https://material.io/guidelines/components/lists-controls.html">
                Material Guidelines
              </Link>
              .
            </li>
          </List>
        </PlatformTableRow>
      </PlatformTable>
    </Section>
  );
};

export default SectionPlatform;
