import React from 'react';
import { Paragraph, Section } from 'docComponents';

const SectionMobile = () => {
  return (
    <Section title="Mobile">
      <Paragraph>
        Use a table view to display the results when selecting an option. If
        using our React Native component, this is done for you.
      </Paragraph>
    </Section>
  );
};

export default SectionMobile;
